import * as React from 'react';
import {Fragment} from 'react';
import BigNumber from 'bignumber.js';
import {bindActionCreators} from 'redux';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import type {CurrencyTO, WalletTO} from '../../api/types/models';
import copy from 'copy-to-clipboard';
import * as Actions from '../../api/Actions';
import {OPEN_POPUP} from '../../reducers/PopupReducer';
import {DEPOSIT_QR_CODE_POPUP} from '../../cmp/popups/top/DepositQRCodePopup';
import {SvgWrapper} from '../../cmp/helpers/SvgWrapper';
import {ReactComponent as SearchIc} from '../../../../assets/images/icon/search-n.svg';
import {ReactComponent as CopyIc} from '../../../../assets/images/icon/copy.svg';
import {ReactComponent as RefreshIc} from '../../../../assets/images/icon/refresh.svg';
import {ReactComponent as SEPAIcon} from '../../../../assets/images/sepa-seeklogo.com.svg';
import {ReactComponent as QrIc} from '../../../../assets/images/icon/qr.svg';
import {AdvCashForm} from './AdvCashForm';
import I18n from '../../cmp/helpers/I18n';
import {CurrencyView} from '../../cmp/helpers/CurrencyView';
import {CurrencyIcon} from '../../cmp/helpers/CurrencyIcon';
import {CurrencyInput} from '../../cmp/helpers/CurrencyInput';
import {URLMAP} from '../../utils/const';
import axios from 'axios';
import {ReactComponent as WarningIc} from '../../../../assets/images/icon/warning-ic.svg';
import {
  isValidEthAddress,
  isValidEosAddress,
  isValidCruUsduUntbAddress,
  isValidWaxAddress,
  isValidXrpAddress,
  isValidTronAddress,
} from './addressValidationHelpers';
import {validate} from 'bitcoin-address-validation';
import WAValidator from 'multicoin-address-validator';
import {ReactComponent as WarningGtiIc} from '../../../../assets/images/icon/warning.svg';
import {ReactComponent as ArrowRightIc} from '../../../../assets/images/icon/arrowRight.svg';
import {ReactComponent as LoaderIc} from '../../../../assets/images/icon/loaderIcon.svg';
import {ReactComponent as SuccessGtiIc} from '../../../../assets/images/icon/successIcon.svg';
import {ReactComponent as RejectGtiIc} from '../../../../assets/images/icon/rejectIcon.svg';
import {ReactComponent as GlobalUnitPayIcon} from '../../../../assets/images/icon/globalUnitPayIcon.svg';
import {ReactComponent as QuantumClearanceIcon} from '../../../../assets/images/icon/quantumClearanceLogo.svg';
import {ReactComponent as ItexIcon} from '../../../../assets/images/blockchains/itex.svg';
import {blockchainIcons, itezCoinsAvailable} from './constants';
import SuccessDialog from '../../cmp/notification/SuccessDialog';
import ErrorDialog from '../../cmp/notification/ErrorDialog';
import EmailCheckingDialog from '../../cmp/notification/EmailCheckingDialog';
import Loader from '../../cmp/Loader';
import {translate} from '../../utils/i18n';
import {FIAT_PROVIDER, getFiatWithdrawFee} from '../../utils/utils';
import BalancesBar from './BalancesBar';
import {getDepositChainInfo, getWithdrawChains} from '../../api/Actions';
import MultiChainDepositData from './MultiChainDepositData';
import SepaWithdraw from './SepaWithdraw';

export const BASE_CURRENCY = 'USD';
export const INTERIM_CURRENCY = 'BTC';
// const FINANCIAL_SUPPORT_EMAIL = 'finance@unitex.one';
const TEST_USER = 'p.artureg@gmail.com';

export const fetcher = axios.create({
  baseURL: window.settings.APIURL,
});

const initialWithdrawState = {
  addr: '',
  amount: '',
  twofaCode: '',
  feeAmount: 0,
  amountHasError: false,
  addressHasError: false,
  codeHasError: false,
  memo: '',
};

const MEMO_CAPABLE_CRYPTO = ['eos', 'cru', 'usdu', 'untb', 'wax', 'xrp'];
const TRON_NETWORK = 'TRON';
const DEFAULT_NETWORK = 'DEFAULT_NETWORK';
const hiddenCurrencies = ['ADA'];
const FIREBLOCKS_ONLY = [
  'BUSD',
  'DOT',
  'TRX',
  'BNB',
  'USDT',
  'ETH',
  'SOL',
  'ADA',
  'USDC',
  'LINK',
  'DAI',
  'UNI',
  'MATIC',
  'BOBC',
  'BTC',
  // 'LTС',
  // 'XRP',
  // 'EOS',
];
const HIDDEN_FIREBLOCKS = ['LTС', 'XRP', 'EOS'];
const WITHDRAWAL_FIREBLOCKS_EXCEPTION = [];

const isMultichainCurrency = currency => {
  return (
    currency.toLowerCase() === 'eth' ||
    currency.toLowerCase() === 'usdt' ||
    currency.toLowerCase() === 'usdc' ||
    currency.toLowerCase() === 'link' ||
    currency.toLowerCase() === 'dai' ||
    currency.toLowerCase() === 'uni' ||
    currency.toLowerCase() === 'bnb' ||
    currency.toLowerCase() === 'trx' ||
    currency.toLowerCase() === 'doge' ||
    currency.toLowerCase() === 'matic' ||
    currency.toLowerCase() === 'bobc'
  );
};

const getBlockchainsForCurrency = currency => {
  if (!currency) {
    return '';
  }

  switch (currency.toLowerCase()) {
    case 'bnb':
      return 'Binance Smart Chain (BEP20)';
    case 'trx':
      return 'Tron (TRC20)';
    case 'doge':
      return 'DogeCoin (Doge)';
  }
  return 'Ethereum (ERC20)';
};

const showDepositGtiFiatError = (error, dict) => {
  switch (error) {
    case 'INSUFFICIENT_BALANCE':
      return translate('balances.insufficient_balance_error', dict);
    case 'BAD_CURRENCY_TYPE':
      return translate('balances.bad_currency_error', dict);
    default:
      return translate('balances.unknown_error', dict);
  }
};

const showGtiAccountLinkingError = (error, dict) => {
  switch (error) {
    case 'ACCESS_DENIED':
      return translate('balances.access_denied_linking', dict);
    default:
      return translate('balances.unknown_error', dict);
  }
};

const showGtiWithdrawError = (error, dict) => {
  switch (error) {
    case 'WRONG_CURRENCY':
    case 'WRONG_ACCOUNT':
      return translate('balances.bad_currency_error', dict);
    case 'VALIDATION_ERROR':
    case 'INCORRECT_AMOUNT':
      return translate('balances.validation_error', dict);
    case 'WITHDRAW_ACCOUNT_TOO_NEW':
      return translate('balances.account_too_new_error', dict);
    case 'INSUFFICIENT_BALANCE':
      return translate('balances.insufficient_balance_error', dict);
    case 'BAD_TWOFA_CODE':
      return translate('BAD_TWOFA_CODE', dict);
    case 'TWO_FACTOR_VECTOR_DISABLED':
      return translate('TWO_FACTOR_VECTOR_DISABLED', dict);
    default:
      return translate('balances.unknown_error', dict);
  }
};

class Balances extends React.PureComponent {
  state = {
    pricesFetched: false,
    isWithdrawFetching: false,
    selectedForDeposit: null,
    selectedForWithdraw: null,
    withdrawNetworkChain: undefined,
    hideZeroBalances: false,
    initialTickers: null,
    curFilter: '',
    withdraw: initialWithdrawState,
    copiedAddress: false,
    copiedMemo: false,
    gtiId: '',
    currentSeconds: new Date().getSeconds(),
    chosenCurrencyType: null,
    chosenUsdDepositMethod: 'gup',
    chosenEurDepositMethod: 'gup',
    withdrawUsdMethod: 'gup',
    withdrawEurMethod: 'gup',
    successDialogIsOpen: false,
    gtiDepositUsdAmount: '',
    gtiDepositEurAmount: '',
    errorDialogIsOpen: false,
    emailCheckingDialogIsOpen: false,
    depositSelectedChain: null,
    additionalDepositSelectedChain: null,
    needChainSelect: false,
  };

  eventCb = {};

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 30000);
    const {
      CurrencyListReducer,
      AccountListReducer,
      getUserSettings,
      getWithdrawUsage,
      clearWithdraw,
      listDepositAddresses,
      getCurrentGtiStatus,
    } = this.props;
    this.props.getWithdrawChains();
    this.initPrices();
    !AccountListReducer.fetching && AccountListReducer.list === null && this.props.getAccountList();
    this.props.getFees();
    getUserSettings(); //todo: prevent double load
    getCurrentGtiStatus();
    listDepositAddresses();
    getWithdrawUsage();
    clearWithdraw();
    this.checkDepositState();
  }

  componentDidUpdate(prevProps, prevState) {
    const {currentSeconds, selectedForWithdraw, chosenCurrencyType, selectedForDeposit} = this.state;
    const {GetGtiStatusReducer, getCurrentGtiStatus, GtiRequestReducer, GtiDepositReduser, WithdrawRequestReducer} =
      this.props;
    if (
      (prevState.currentSeconds !== currentSeconds &&
        selectedForWithdraw &&
        GetGtiStatusReducer.data.status !== 'Linked' &&
        chosenCurrencyType === 'FIAT') ||
      (prevState.selectedForWithdraw !== selectedForWithdraw &&
        selectedForWithdraw &&
        GetGtiStatusReducer.data.status !== 'Linked' &&
        chosenCurrencyType === 'FIAT')
    ) {
      getCurrentGtiStatus();
    }

    if (
      (prevState.currentSeconds !== currentSeconds &&
        selectedForDeposit &&
        GetGtiStatusReducer.data.status !== 'Linked' &&
        chosenCurrencyType === 'FIAT') ||
      (prevState.selectedForDeposit !== selectedForDeposit &&
        selectedForDeposit &&
        GetGtiStatusReducer.data.status !== 'Linked' &&
        chosenCurrencyType === 'FIAT')
    ) {
      getCurrentGtiStatus();
    }

    if (
      (prevProps.GtiRequestReducer.fetching && GtiRequestReducer.completed) ||
      (prevProps.GtiDepositReduser.fetching && GtiDepositReduser.completed)
    ) {
      this.setState({successDialogIsOpen: true});
    }

    if (prevProps.WithdrawRequestReducer.fetching && WithdrawRequestReducer.completed) {
      this.setState({emailCheckingDialogIsOpen: true});
    }

    if (
      (prevProps.GtiRequestReducer.fetching && GtiRequestReducer.error) ||
      (prevProps.GtiDepositReduser.fetching && GtiDepositReduser.error) ||
      (prevProps.WithdrawRequestReducer.fetching && WithdrawRequestReducer.error)
    ) {
      this.setState({errorDialogIsOpen: true});
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    this.clickOutsidePopup && document.removeEventListener('click', this.clickOutsidePopup);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.initialTickers ||
      !nextProps.tickers ||
      !nextProps.tickers.tickers ||
      !nextProps.tickers.tickers.next
    )
      return;

    let newState = null;
    for (let marketId in nextProps.tickers.tickers.next) {
      if (!this.state.initialTickers.next[marketId]) {
        if (!newState) {
          newState = JSON.parse(JSON.stringify(this.state.initialTickers));
        }
        newState.next[marketId] = nextProps.tickers.tickers.next[marketId];
      }
    }

    if (!!newState) {
      this.setState({initialTickers: newState, hideZeroBalances: false});
    }
  }

  tick() {
    this.setState({
      currentSeconds: new Date().getSeconds(),
    });
  }

  getWithdrawNetworkName = currencyName => {
    const {network = ''} = this.state.withdrawNetworkChain || {};

    if (network === DEFAULT_NETWORK) {
      return getBlockchainsForCurrency(currencyName);
    }

    return network || getBlockchainsForCurrency(currencyName);
  };

  isVerifiedUser = () => {
    const {SessionReducer} = this.props;
    const {masterPersonality} = SessionReducer;
    const kycStatus = masterPersonality.curKYCustS;
    return kycStatus === 'VERIFIED_PERSONAL' || kycStatus === 'VERIFIED_CORPORATE';
  };

  redirectToVerification = () => {
    this.props.history.push(URLMAP.PROFILE + '/Verifications');
  };

  toggleDepositForm = (walletId, curType, cryptoCurr) => {
    const {WsWalletReducer} = this.props;
    if (!this.isVerifiedUser()) {
      return this.redirectToVerification();
    }

    let setValue = null;
    let walletCurCode = null;

    if (this.state.selectedForDeposit !== walletId) setValue = walletId;

    for (const curCode of Object.keys(WsWalletReducer.byCurrencyMap)) {
      const wallet = WsWalletReducer.byCurrencyMap[curCode];
      if (wallet.identificatSymbol == walletId) {
        walletCurCode = curCode;
        break;
      }
    }

    const stateToSet = {
      depositSelectedChain: null,
      additionalDepositSelectedChain: null,
      needChainSelect: false,
      selectedForDeposit: setValue,
      selectedForWithdraw: null,
      withdrawNetworkChain: undefined,
      withdraw: initialWithdrawState,
    };

    const hasAdditionalChain = (this.props.GetWithdrawChainsReducer.data.respItemCollection || [])
      .filter(({internalCode}) => !HIDDEN_FIREBLOCKS.some(cur => cur === internalCode))
      .some(({internalCode}) => internalCode.toLowerCase() === walletCurCode.toLowerCase());

    if (isMultichainCurrency(walletCurCode) || hasAdditionalChain || itezCoinsAvailable.includes(cryptoCurr)) {
      stateToSet.needChainSelect = true;
      this.setState(stateToSet);
      return;
    }

    this.setState(stateToSet);

    if (!!setValue && curType !== 'FIAT') {
      if (!WsWalletReducer.depositAddressMap[walletId]) {
        this.requestDepAddr(walletId);
      }
    }

    this.props.clearWithdraw();
    return false;
  };

  // calcBaseValue = (baseCur, cur, amount) => {
  //   if (!cur) return 0;
  //   if (baseCur.identificatSymbol === cur.identificatSymbol) return amount;
  //   if (!amount || amount === 0) return 0;
  //
  //   const markets = this.props.MarketListReducer.list || [];
  //   const market = markets.find(m => m.leftMonetaryUnitCode.toLowerCase() === cur.mUCryptogram.toLowerCase() && m.rightMonetaryUnitCode.toLowerCase() === baseCur.mUCryptogram.toLowerCase());
  //   if (!market) return null;
  //   const price = this.state.initialTickers && this.state.initialTickers.next && this.state.initialTickers.next[market.identificatSymbol];
  //   if (!price) return "?";
  //
  //   return amount * price;
  // };

  copyToClipboard(addr, propName) {
    copy(addr);
    this.setState({[propName]: true});
    setTimeout(() => {
      this.setState({[propName]: false});
    }, 1000);
  }

  initPrices() {
    const {WsTickersReducer, MarketListReducer} = this.props;
    const marketList = MarketListReducer.list || [];
    const tickers = WsTickersReducer.tickers || {};
    if (!marketList.length || !tickers.next || !Object.keys(tickers.next).length) {
      setTimeout(() => this.initPrices(), 200);
    } else {
      this.setState({pricesFetched: true, initialTickers: JSON.parse(JSON.stringify(tickers))});
    }
  }

  toggleForm = (walletId, depAddr, currentT, withdrawCurrT, cryptoCurr) => {
    if (currentT) {
      this.setState({chosenCurrencyType: currentT});
      this.toggleDepositForm(walletId, currentT, cryptoCurr);
    } else {
      this.setState({chosenCurrencyType: withdrawCurrT});
      this.toggleWithdrawForm(walletId);
    }

    const clickOutsidePopup = ev => {
      const mainContainer = document.querySelector(`#main-${walletId}`);
      const depositContainer = document.querySelector(`#deposit-${walletId}`);
      const withdrawalContainer = document.querySelector(`#withdrawal-${walletId}`);
      if (
        mainContainer &&
        !mainContainer.contains(ev.target) &&
        !depositContainer.contains(ev.target) &&
        !withdrawalContainer.contains(ev.target)
      ) {
        !ev.target.hasAttribute('toggle-btn') && this.setState({selectedForDeposit: null, selectedForWithdraw: null});
        document.removeEventListener('click', clickOutsidePopup);
        this.eventCb[walletId] = null;
      }
    };

    if (!this.eventCb[walletId]) {
      this.eventCb[walletId] = clickOutsidePopup;
      document.addEventListener('click', clickOutsidePopup);
    }
  };

  toggleWithdrawForm(walletId) {
    if (!this.isVerifiedUser()) {
      return this.redirectToVerification();
    }
    let setValue = null;
    if (this.state.selectedForWithdraw !== walletId) setValue = walletId;
    this.props.clearWithdraw();
    this.setState({
      selectedForDeposit: null,
      withdrawNetworkChain: undefined,
      selectedForWithdraw: setValue,
      withdraw: initialWithdrawState,
    });
  }

  requestSepaWithdraw = async ({currencyId, amount, twofaCode, bankeraDepositId, receivingTargetAddr}) => {
    if (this.state.isWithdrawFetching) {
      return;
    }

    this.setState({isWithdrawFetching: true});

    this.props
      .requestWithdraw({
        bankeraDepositId,
        payGateType: 'BANKERA',
        monetaryUnitId: currencyId,
        receivingTargetAddr,
        wtdQty: amount,
        memo: '',
        tfCryptogram: twofaCode,
      })
      .then(r => {
        this.setState({isWithdrawFetching: false});
      });
  };

  async requestWithdraw(currencyId, currencyType, currencyName) {
    if (this.state.isWithdrawFetching) {
      return;
    }

    this.setState({isWithdrawFetching: true});

    const {network = '', id: networkId = ''} = this.state.withdrawNetworkChain || {};
    let state = this.state.withdraw,
      newState = {...this.state.withdraw},
      hasErrors = false,
      user = this.props.SessionReducer.masterPersonality,
      account = this.props.AccountListReducer.list[0];

    newState = {...this.state.withdraw};
    // if (!state.amount || parseFloat(state.amount) <= 0) {
    // // it's a temporary fix for ETH
    const isFiat = currencyType === 'FIAT';

    if (!state.amount || parseFloat(state.amount) <= 0) {
      newState.amountHasError = true;
      hasErrors = true;
    } else {
      newState.amountHasError = false;
    }

    try {
      const networkType = settings.GLOBAL_PASS.WIDGET.includes('/stage/') ? 'testnet' : 'prod';

      if (
        !isFiat &&
        (!state.addr.trim() ||
          ((currencyName === 'BNB' ||
            currencyName === 'ETH' ||
            currencyName === 'USDC' ||
            (currencyName === 'USDT' && network !== TRON_NETWORK) ||
            currencyName === 'LINK' ||
            currencyName === 'DAI' ||
            currencyName === 'UNI' ||
            currencyName === 'BOBC' ||
            currencyName === 'MATIC') &&
            !isValidEthAddress(state.addr)) ||
          (currencyName === 'BTC' && !validate(state.addr)) ||
          (currencyName === 'LTC' && !WAValidator.validate(state.addr, 'LTC', networkType)) ||
          (currencyName === 'DOGE' && !WAValidator.validate(state.addr, 'DOGE', networkType)) ||
          ((currencyName === 'CRU' || currencyName === 'USDU' || currencyName === 'UNTB') &&
            (await isValidCruUsduUntbAddress(currencyName, state.addr.trim())) !== 200) ||
          (currencyName === 'EOS' && (await isValidEosAddress(state.addr.trim())) !== 'OK') ||
          (currencyName === 'WAX' && (await isValidWaxAddress(state.addr.trim())) !== 200) ||
          (currencyName === 'XRP' && (await isValidXrpAddress(state.addr.trim())) !== true) ||
          (currencyName === 'USDT' &&
            network === TRON_NETWORK &&
            (await isValidTronAddress(state.addr.trim())) !== true) ||
          (currencyName === 'TRX' && (await isValidTronAddress(state.addr.trim())) !== true))
      ) {
        newState.addressHasError = true;
        hasErrors = true;
      } else {
        newState.addressHasError = false;
      }
    } catch (e) {
      console.debug('ERROR!!!', e);
      newState.addressHasError = true;
      hasErrors = true;
    }

    // if (!state.twofaCode.trim()) {
    //   newState.codeHasError = true;
    //   hasErrors = true;
    // } else {
    //   newState.codeHasError = false;
    // }

    this.setState({withdraw: newState});

    if (hasErrors) {
      this.setState({isWithdrawFetching: false});

      return;
    }

    const hasSpecialNetwork = !!network && network !== DEFAULT_NETWORK;

    this.props
      .requestWithdraw({
        // accountId: account.identificatSymbol,
        ...(isFiat && {payGateType: 'GTINET'}),
        ...(hasSpecialNetwork && {currencyFireblocksId: networkId}),
        monetaryUnitId: currencyId,
        receivingTargetAddr: state.addr,
        wtdQty: state.amount,
        memo: state.memo,
        tfCryptogram: state.twofaCode,
      })
      .then(r => {
        this.setState({isWithdrawFetching: false});
      });
  }

  onWithdrawChange(e) {
    let newState = {...this.state.withdraw},
      val = e.target.value.trim();
    newState[e.target.name] = val;
    this.setState({withdraw: newState});
  }

  onGtiIdChange(e) {
    if (e.target.value.length <= 6) {
      this.setState({gtiId: e.target.value.trim()});
    }
  }

  changeWithdrawAmount = (currType, name, value) => {
    const {FeesSettingsReducer} = this.props;
    const account = this.props.AccountListReducer.list[0],
      plan = account.payScheme;
    const walletId = this.state.selectedForWithdraw;
    const wallet = this.props.WsWalletReducer.list.find(w => w.identificatSymbol == walletId);
    const currency = this.props.CurrencyListReducer.list.find(cur => cur.mUCryptogram == wallet.monetaryUnitCode);
    const isFreeTransaction = plan.feelessTransactions;

    if (!FeesSettingsReducer || !FeesSettingsReducer.completed) {
      return;
    }
    let amountWithFee = '';
    if (currType === 'FIAT') {
      let withdrawFee = getFiatWithdrawFee(
        FeesSettingsReducer.data.actionsFees,
        currency.mUCryptogram,
        FIAT_PROVIDER.GTI
      );
      withdrawFee = parseFloat(withdrawFee.replace('/%/g', '').trim());
      if (isFreeTransaction) withdrawFee = 0;
      if (typeof withdrawFee == 'number' && withdrawFee > 0) {
        const multiplier = 1 + (1 * withdrawFee) / 100;
        amountWithFee = new BigNumber(value).multipliedBy(multiplier).toNumber();
      }
    } else if (isFreeTransaction) {
      amountWithFee = new BigNumber(value).toNumber();
    } else {
      const fee =
        this.state.withdrawNetworkChain && this.state.withdrawNetworkChain.withdrawFee !== undefined
          ? this.state.withdrawNetworkChain.withdrawFee
          : currency.excludeUF;
      amountWithFee = new BigNumber(value).plus(new BigNumber(fee)).toNumber();
    }
    this.setState({
      withdraw: {
        ...this.state.withdraw,
        [name]: value,
        amountWithFee,
      },
    });
  };

  clearFilter = () => {
    this.setState({hideZeroBalances: false, filter: null});
  };

  requestDepAddr = purseIdentifier => {
    this.props.requestDepositAddress({purseIdentifier});
  };

  checkDepositState() {
    const id = /id=([^&]+)/.exec(location.search)[1];
    if (id) {
      fetcher.request({url: `quantum/pgc/pmnt/charge-state-return/quantum?id=${id}`, method: 'GET'});
    }
  }

  checkDepositState() {
    const exec = /id=([^&\?]+)/.exec(location.search);
    if (exec && exec[1]) {
      const id = parseInt(exec[1], 10);
      if (id) {
        fetcher.request({url: `quantum/pgc/pmnt/charge-state-return/quantum?id=${id}`, method: 'GET'});
      }
    }
  }

  pickDepositAddress(walletId) {
    const {WsWalletReducer} = this.props;

    let depAddrList = WsWalletReducer.depositAddressMap[walletId];
    if (depAddrList && depAddrList.length) {
      let depAddr = depAddrList[0].targetPlaceName;
      let depMemo = depAddrList[0].memas;
      let minDepositAmount = depAddrList[0].minDepositAmount;
      return {depAddr, depMemo, minDepositAmount};
    }

    return null;
  }

  convertCurrencyRate(WsWalletReducer, amount, currencyFrom, currencyTo) {
    const INTERIM_CURRENCY = 'BTC';
    const crossCourses = WsWalletReducer.crossCourses || {};
    if (currencyFrom === currencyTo) {
      return amount;
    }
    if (crossCourses && crossCourses[currencyTo] && crossCourses[currencyTo][currencyFrom]) {
      return amount / crossCourses[currencyTo][currencyFrom];
    }
    if (
      crossCourses &&
      crossCourses[INTERIM_CURRENCY] &&
      crossCourses[INTERIM_CURRENCY][currencyFrom] &&
      crossCourses[INTERIM_CURRENCY][currencyTo]
    ) {
      let interimValue = amount / crossCourses[INTERIM_CURRENCY][currencyFrom];
      let convertedValue = interimValue * crossCourses[INTERIM_CURRENCY][currencyTo];
      return convertedValue;
    }

    return null;
  }

  gtiRequest = async () => {
    await this.props.requestGti({externalId: this.state.gtiId});
    await this.props.getCurrentGtiStatus();
  };

  onDepositMethodChange = e => {
    if (e.target.name === 'depositMethodUSD') this.setState({chosenUsdDepositMethod: e.target.value});
    if (e.target.name === 'depositMethodEUR') this.setState({chosenEurDepositMethod: e.target.value});
  };

  handleOpenSuccessDialog = () => {
    this.setState(state => ({successDialogIsOpen: !state.successDialogIsOpen}));
  };

  handleOpenErrorDialog = () => {
    this.setState(state => ({errorDialogIsOpen: !state.errorDialogIsOpen}));
  };

  handleOpenEmailCheckingDialog = () => {
    this.setState(state => ({emailCheckingDialogIsOpen: !state.emailCheckingDialogIsOpen}));
  };

  returnDigits = () => {
    const {currencyType = ''} = this.props;
    if (!this.digitsCash || this.digitsCash.curType !== currencyType) {
      this.digitsCash = {
        curType: currencyType,
        digits: currencyType ? CURRENCIES_DIGITS.get(currencyType.toUpperCase()) : 2,
      };
    }
    return this.digitsCash.digits;
  };

  changeGtiDepositAmount = (currency, e) => {
    const value = e.target.value;
    let numberRegex;
    const digits = this.returnDigits();
    if (digits > 0) {
      numberRegex = new RegExp(`(^\\d{0,9}$)|(^\\d*\\.\\d{0,${digits}}$)`);
    } else {
      numberRegex = /^[\d\s]*$/;
    }
    if (numberRegex.test(value)) {
      if (currency === 'USD') this.setState({gtiDepositUsdAmount: value});
      if (currency === 'EUR') this.setState({gtiDepositEurAmount: value});
    }
  };

  changeWithdrawFiatMethod = e => {
    if (e.target.name === 'withdrawRadioUSD') this.setState({withdrawUsdMethod: e.target.value});
    if (e.target.name === 'withdrawRadioEUR') this.setState({withdrawEurMethod: e.target.value});
  };

  renderDepositChainSelector = () => {
    const setDepositBlockchain = (blockchain, e) => {
      const {WsWalletReducer} = this.props;
      const walletId = this.state.selectedForDeposit;

      e.preventDefault();
      e.stopPropagation();
      this.setState({depositSelectedChain: blockchain});
      if (!!walletId && !WsWalletReducer.depositAddressMap[walletId]) {
        this.requestDepAddr(walletId);
      }
    };

    const selectAdditionalChain = chainId => {
      this.props.clearAdditionalDepositChainData();
      this.setState({additionalDepositSelectedChain: chainId});

      this.props.getDepositChainInfo({
        purseIdentifier: this.state.selectedForDeposit,
        currencyFireblocksId: chainId,
      });
    };

    const {selectedForDeposit} = this.state;
    const {WsWalletReducer, SessionReducer = {}} = this.props;
    const {electronicMailVal = ''} = SessionReducer.masterPersonality || {};

    const wallet =
      WsWalletReducer &&
      WsWalletReducer.list &&
      WsWalletReducer.list.find(w => w.identificatSymbol == selectedForDeposit);
    const {monetaryUnitCode = ''} = wallet || {};
    const blockchain = getBlockchainsForCurrency(monetaryUnitCode);
    const isBNB = monetaryUnitCode.toUpperCase() === 'BNB';
    const isFireBlocksOnly = FIREBLOCKS_ONLY.some(currency => monetaryUnitCode.toUpperCase() === currency);

    return (
      <div className='text-center add-info-deposit'>
        <div className='add-info-header chain-selector'>
          <div>
            <I18n tKey='balances.token_chain_diclaimer' />
          </div>
          <div style={{marginBottom: 8}}>
            <I18n tKey='balances.select_blockchain' />:
          </div>
          <div className='deposit-chain-btns'>
            {blockchain && !isFireBlocksOnly && (
              <button
                className='btn btn-purple btn-full icon-blockchain'
                onClick={setDepositBlockchain.bind(this, blockchain)}
                toggle-btn='toggle-btn'
              >
                {blockchainIcons[blockchain]} {blockchain}
              </button>
            )}
            {(this.props.GetWithdrawChainsReducer.data.respItemCollection || [])
              .filter(({internalCode}) => !HIDDEN_FIREBLOCKS.some(cur => cur === internalCode))
              .filter(({internalCode}) => internalCode.toLowerCase() === monetaryUnitCode.toLowerCase())
              .map(({description, network, id}) => {
                return (
                  <button
                    key={id}
                    onClick={() => selectAdditionalChain(id)}
                    className='btn btn-purple btn-full icon-blockchain'
                    toggle-btn='toggle-btn'
                  >
                    {blockchainIcons[network]} <span>{description}</span>
                  </button>
                );
              })}
            {itezCoinsAvailable.includes(monetaryUnitCode) && (
              <Link
                to={{pathname: URLMAP.EXCHANGE, query: {itezCurr: monetaryUnitCode}}}
                className='btn btn-purple btn-full icon-blockchain'
              >
                <ItexIcon /> <span>Itez (Bank Card)</span>
              </Link>
            )}
          </div>
          {isBNB && (
            <div style={{marginTop: 8}}>
              <I18n tKey='balances:bnb-hint' />
            </div>
          )}
        </div>
      </div>
    );
  };
  render() {
    const {
      WithdrawRequestReducer,
      GetDepositAddressReducer,
      WsWalletReducer,
      CurrencyListReducer,
      DepositAddressesListReducer,
      AccountListReducer,
      WithdrawUsageReducer,
      UserSettingsReducer,
      GetGtiStatusReducer,
      GtiRequestReducer,
      LngReducer,
      GtiDepositReduser,
      FeesSettingsReducer,
      SessionReducer,
    } = this.props;
    const user = SessionReducer.masterPersonality;
    const {successDialogIsOpen, errorDialogIsOpen, emailCheckingDialogIsOpen} = this.state;
    if ([CurrencyListReducer.list, WsWalletReducer.list, AccountListReducer.list].some(val => val === null)) {
      return null;
    }
    if (
      [
        CurrencyListReducer.fetching,
        WsWalletReducer.fetching,
        AccountListReducer.fetching,
        this.props.GetWithdrawChainsReducer.fetching,
      ].some(val => val)
    ) {
      return <div>Loading...</div>;
    }
    //if (!this.state.pricesFetched) return <div>Loading...</div>;
    const account = AccountListReducer.list[0];
    if (!account) {
      return (
        <div className='text-center'>
          <I18n tKey='profile.empty_account_list' />
        </div>
      );
    }
    const plan = account.payScheme;
    const areTransactionsAllowed = user.isWithdrawActive;

    // let walletsList = WsWalletReducer.list,
    //   curs = CurrencyListReducer.list,
    //   baseCur = curs.filter((c: CurrencyTO) => c.mUCryptogram === BASE_CURRENCY)[0];

    const filterCur = !!this.state.curFilter ? this.state.curFilter.toLowerCase() : '';
    // let totalBaseValue = null;
    // if (this.state.pricesFetched) {
    //   totalBaseValue = 0;
    //   walletsList.forEach(w => {
    //     const cur = curs.filter(elem => w.monetaryUnitCode === elem.mUCryptogram);
    //     w.baseValue = this.calcBaseValue(baseCur, cur[0], w.aValue + w.resValue);
    //     totalBaseValue += w.baseValue;
    //   });
    // }
    //
    //
    // walletsList = walletsList.filter(w => !!curs[w.monetaryUnitCode]);
    // walletsList.sort((a, b) => curs[a.monetaryUnitCode].title.localeCompare(curs[b.monetaryUnitCode].title));

    const crossCourses = WsWalletReducer.crossCourses || {};
    let interimValue;
    const totalInUSD = Object.keys(WsWalletReducer.byCurrencyMap || {}).reduce((s, cc) => {
      const wallet: WalletTO = WsWalletReducer.byCurrencyMap[cc];
      if (cc.toUpperCase() === BASE_CURRENCY) {
        return s + wallet.aValue + wallet.resValue;
      } else if (
        wallet &&
        crossCourses &&
        crossCourses[BASE_CURRENCY] &&
        crossCourses[BASE_CURRENCY][wallet.monetaryUnitCode]
      ) {
        return s + (wallet.aValue + wallet.resValue) / crossCourses[BASE_CURRENCY][wallet.monetaryUnitCode];
      } else if (
        crossCourses &&
        crossCourses[INTERIM_CURRENCY] &&
        crossCourses[INTERIM_CURRENCY][wallet.monetaryUnitCode] &&
        crossCourses[BASE_CURRENCY] &&
        crossCourses[BASE_CURRENCY][INTERIM_CURRENCY]
      ) {
        interimValue = wallet.aValue / crossCourses[INTERIM_CURRENCY][wallet.monetaryUnitCode];
        return s + interimValue / crossCourses[BASE_CURRENCY][INTERIM_CURRENCY];
      } else {
        return s;
      }
    }, 0);
    let dailyLeft,
      monthlyLeft,
      withdrowedLimit = 0,
      usageCur = null;
    if (plan && WithdrawUsageReducer.data) {
      dailyLeft = plan.eDayWithdrawTop - WithdrawUsageReducer.data.eDay;
      monthlyLeft = plan.eMonthWithdrawTop - WithdrawUsageReducer.data.eMonth;
      withdrowedLimit = new BigNumber(WithdrawUsageReducer.data.eDay || 0)
        .multipliedBy(100)
        .div(plan.eDayWithdrawTop || 0)
        .toNumber();
    }

    let withdrawError,
      withdrawErrorParam = null;
    if (WithdrawRequestReducer.error) {
      let message = WithdrawRequestReducer.error.message;
      if (message) {
        if (message.indexOf('NOT_ENOUGH_BALANCE') === 0) {
          withdrawError = 'error:NOT_ENOUGH_BALANCE';
        } else if (message.indexOf('PAY_PLAN_MIN_TX_LIMIT_NOT_REACHED') === 0) {
          withdrawError = 'error:PAY_PLAN_MIN_TX_LIMIT_NOT_REACHED';
          if (message.indexOf('|') !== -1) {
            withdrawErrorParam = '(' + message.split('|')[1] + ')';
          }
        }
      }
      if (!withdrawError) {
        withdrawError = WithdrawRequestReducer.error.error;
      }
    }

    const gtiNotLinkedLayout = (
      <Fragment>
        <div className='gti-current-status-section'>
          <SvgWrapper className='ic-size-06' SvgCmp={WarningGtiIc} />
          <div className='gti-current-status gti-current-status-warning'>
            <I18n tKey='common.gti_status_msg' /> <I18n tKey='common.gti_not_linked' />
          </div>
        </div>
        <div className='gti-message'>
          <I18n tKey='common.having_id_notification' />
          <span className='or-text'>
            <I18n tKey='common.or' />
          </span>

          <I18n tKey='common.gti_not_linked_msg' />
          <Link to={{pathname: 'https://gtinet.ch/'}} target='_blank'>
            <span className='global-unit-pay-link'>
              <I18n tKey='common.global_unit_pay' />
            </span>
          </Link>
          <SvgWrapper className='ic-size-08 arrow-icon' SvgCmp={ArrowRightIc} />
        </div>
        <div className='box-control gti-section'>
          <div className='gti-input-section'>
            <label className='label'>
              <I18n tKey='common.gti_id_label' />
            </label>
            <input
              type='text'
              name='gtiId'
              value={this.state.gtiId}
              className='form-control'
              placeholder={GetGtiStatusReducer.data.externalId}
              onChange={this.onGtiIdChange.bind(this)}
            />
          </div>
          <div style={{minWidth: 130}} className='add-info-body-item btn-wrapper'>
            <a
              className={`btn btn-primary ${this.state.gtiId.length !== 6 ? 'disabled' : ''}`}
              onClick={this.gtiRequest}
            >
              <I18n tKey='balances.add_link' />
            </a>
          </div>
        </div>
      </Fragment>
    );

    const gtiLinkInitiatedLayout = (
      <Fragment>
        <div className='gti-current-status-section'>
          <div className='confirmation-letter-container'>
            <div className='confirmation-letter-text'>
              <I18n tKey='common.confirmation_letter_waiting' />
            </div>
            <div className='confirmation-letter-loader'>
              <SvgWrapper SvgCmp={LoaderIc} />
            </div>
          </div>
        </div>
      </Fragment>
    );

    const gtiConfirmedByUserLayout = (
      <Fragment>
        <div className='gti-current-status-section'>
          <SvgWrapper className='ic-size-06' SvgCmp={SuccessGtiIc} />
          <div className='gti-current-status gti-current-status-success'>
            <I18n tKey='common.gti_status_msg' /> <I18n tKey='common.gti_confirmed_by_user' />
          </div>
        </div>
        <div className='gti-message'>
          <I18n tKey='common.gti_confirmed_by_user_msg' />
        </div>
        <div className='box-control gti-section'>
          <div className='gti-input-section'>
            <label className='label'>
              <I18n tKey='common.gti_id_label' />
            </label>
            <input
              type='text'
              name='gtiId'
              value={this.state.gtiId}
              className='form-control'
              placeholder={GetGtiStatusReducer.data.externalId}
              onChange={this.onGtiIdChange.bind(this)}
            />
          </div>
        </div>
      </Fragment>
    );

    const gtiRejectedByAdminLayout = (
      <Fragment>
        <div className='gti-current-status-section'>
          <SvgWrapper className='ic-size-06' SvgCmp={RejectGtiIc} />
          <div className='gti-current-status gti-current-status-warning'>
            <I18n tKey='common.gti_status_msg' /> <I18n tKey='common.gti_rejected_by_admin' />
          </div>
        </div>
        <div className='gti-message'>
          <I18n tKey='common.gti_rejected_by_admin_msg' />
        </div>
        <div className='box-control gti-section'>
          <div className='gti-input-section'>
            <label className='label'>
              <I18n tKey='common.gti_id_label' />
            </label>
            <input
              type='text'
              name='gtiId'
              value={this.state.gtiId}
              className='form-control'
              placeholder={GetGtiStatusReducer.data.externalId}
              onChange={this.onGtiIdChange.bind(this)}
            />
          </div>
        </div>
      </Fragment>
    );
    const {electronicMailVal = ''} = user || {};

    return (
      <Fragment>
        <BalancesBar />
        <div className='tab-pane-body'>
          <section className='block-balance'>
            <div className='block-balance-header'>
              <div className='block-balance-header-search'>
                <div className='box-control'>
                  <input
                    type='text'
                    placeholder=''
                    className='form-control'
                    value={this.state.filter || ''}
                    onChange={e => this.setState({filter: e.target.value})}
                  />
                  <SvgWrapper themeWatch className='icon icon-search ic-size-02' SvgCmp={SearchIc} />
                </div>
              </div>
              <div className='block-balance-header-filter'>
                <div className='ui-form-checkbox'>
                  <input
                    type='checkbox'
                    id='checkbox19'
                    checked={this.state.hideZeroBalances}
                    onChange={() => this.setState({hideZeroBalances: !this.state.hideZeroBalances})}
                  />
                  <label htmlFor='checkbox19'>
                    <div className='hide-zero-balance'>
                      <I18n tKey='common.hide' /> 0 <I18n tKey='balances.balances' />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className='block-balance-body'>
              <table className='table-balance'>
                <thead className='table-balance-header'>
                  <tr>
                    <th>
                      <I18n tKey='common.coin' />
                    </th>
                    <th>{/*<I18n tKey='common.name' />*/}</th>
                    <th className='text-right'>
                      <I18n tKey='balances.total_balance' />
                    </th>
                    <th className='text-right'>
                      <I18n tKey='balances.available' />
                    </th>
                    <th className='text-right'>
                      <I18n tKey='balances.orders' />
                    </th>
                    {/* <th className="text-right"><I18n tKey="balances.value"/></th> */}
                    {areTransactionsAllowed && (
                      <th className='td-actions text-center'>
                        <I18n tKey='common.actions' />
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className='table-balance-body'>
                  {(CurrencyListReducer.list || [])
                    .filter(currency => {
                      const shouldBeHidden = hiddenCurrencies.some(
                        currencyName => currencyName === currency.mUCryptogram.toUpperCase()
                      );

                      if (shouldBeHidden && electronicMailVal !== TEST_USER) {
                        return false;
                      }

                      return true;
                    })
                    .map((c: CurrencyTO, i) => {
                      // if (c.currentT === "FIAT") {
                      //   return;
                      // }
                      const w: WalletTO = WsWalletReducer.byCurrencyMap[c.mUCryptogram];
                      if (!w) {
                        return;
                      }
                      const cur = w.monetaryUnitCode,
                        curId = c.identificatSymbol,
                        curType = c.currentT,
                        total = w.aValue + w.resValue,
                        isDepVisible = this.state.selectedForDeposit === w.identificatSymbol,
                        isWithdrawVisible = this.state.selectedForWithdraw === w.identificatSymbol;
                      let withdrawFee = c.excludeUF;
                      if (this.state.hideZeroBalances && (!total || total == null)) return null;
                      if (
                        this.state.filter &&
                        c.mUCryptogram.toLowerCase().indexOf(this.state.filter.toLowerCase()) === -1 &&
                        c.mUBanner.toLowerCase().indexOf(this.state.filter.toLowerCase()) === -1
                      )
                        return null;
                      if (
                        !!filterCur &&
                        !cur.toLowerCase().includes(filterCur) &&
                        !curs[cur].title.toLowerCase().includes(filterCur)
                      )
                        return null;

                      let depAddr = null;
                      let depMemo = null;
                      let depError = null;
                      let minDepositAmount = null;
                      let actionFees = null;

                      if (this.state.selectedForDeposit === w.identificatSymbol) {
                        let fetchedAddress = this.pickDepositAddress(w.identificatSymbol);
                        if (fetchedAddress) {
                          depAddr = fetchedAddress.depAddr;
                          depMemo = fetchedAddress.depMemo;
                          minDepositAmount = fetchedAddress.minDepositAmount;
                        }

                        depError = WsWalletReducer.depositAddressErrorMap[w.identificatSymbol];

                        if (FeesSettingsReducer && FeesSettingsReducer.completed) {
                          actionFees = FeesSettingsReducer.data.actionsFees.find(
                            c => c.currencyCode.toLowerCase() == w.monetaryUnitCode.toLowerCase()
                          );
                        }
                      }

                      const settings = UserSettingsReducer.data || {};
                      const withData = WithdrawRequestReducer.data || {};
                      let convertedTxMin = null;
                      let txMinCurrency = null;
                      if (isWithdrawVisible) {
                        if (c.hasMinimumWithdrawAmount) {
                          convertedTxMin = c.minWithdrawAmount;
                          txMinCurrency = c.mUCryptogram;
                        } else {
                          convertedTxMin = this.convertCurrencyRate(
                            WsWalletReducer,
                            plan.leastTransactionWithdraw,
                            plan.monetaryUnitCode,
                            c.mUCryptogram
                          );
                          txMinCurrency = c.mUCryptogram;
                          if (convertedTxMin == null) {
                            convertedTxMin = plan.leastTransactionWithdraw;
                            txMinCurrency = plan.monetaryUnitCode;
                          }
                        }
                        if (curType === 'FIAT' && FeesSettingsReducer && FeesSettingsReducer.completed) {
                          withdrawFee = getFiatWithdrawFee(
                            FeesSettingsReducer.data.actionsFees,
                            w.monetaryUnitCode,
                            FIAT_PROVIDER.GTI
                          );
                        }
                      }
                      const withdrawNetworkShouldBeSelected = (
                        this.props.GetWithdrawChainsReducer.data.respItemCollection || []
                      )
                        .filter(
                          ({internalCode}) =>
                            !HIDDEN_FIREBLOCKS.some(cur => cur === internalCode) &&
                            !WITHDRAWAL_FIREBLOCKS_EXCEPTION.some(cur => cur === internalCode)
                        )
                        .some(({internalCode}) => internalCode.toLowerCase() === c.mUCryptogram.toLowerCase());

                      if (plan.feelessTransactions) {
                        withdrawFee = 0;
                      }
                      const isFireBlocksOnly = FIREBLOCKS_ONLY.some(
                        currency => c.mUCryptogram.toUpperCase() === currency
                      );

                      return w ? (
                        <Fragment key={i}>
                          <tr
                            id={`main-${w.identificatSymbol}`}
                            className={`${c.activityFlag ? '' : 'status-maintenance'} ${
                              isDepVisible || isWithdrawVisible ? 'open-next-tr' : ''
                            }`}
                          >
                            <td className='text-left'>
                              <CurrencyIcon curName={c.mUCryptogram} />
                            </td>
                            <td className='text-left'>{/*{c.mUBanner}*/}</td>
                            <td className='text-right'>
                              <CurrencyView
                                appearance={c.currentT}
                                amount={new BigNumber(w.aValue).plus(w.resValue).toNumber()}
                                type={c.mUCryptogram}
                              />
                            </td>
                            <td className='text-right'>
                              <CurrencyView appearance={c.currentT} amount={w.aValue} type={c.mUCryptogram} />
                            </td>
                            <td className='text-right'>
                              <CurrencyView appearance={c.currentT} amount={w.resValue} type={c.mUCryptogram} />
                            </td>
                            {/* <td className="text-right">{w.amountInBasicCurrency ?
                        <CurrencyView appearance={c.currentT} amount={w.amountInBasicCurrency}
                                      type={c.mUCryptogram}/> : "n/a"}</td> */}
                            {c.activityFlag &&
                              areTransactionsAllowed &&
                              c.mUCryptogram !== 'XRP' &&
                              c.mUCryptogram !== 'EOS' &&
                              c.mUCryptogram !== 'LTC' && (
                                <td className='td-actions text-center'>
                                  <div className='td-actions-btn'>
                                    <div
                                      toggle-btn='toggle-btn'
                                      className={`btn ${isDepVisible ? 'open' : ''}`}
                                      onClick={this.toggleForm.bind(
                                        this,
                                        w.identificatSymbol,
                                        depAddr,
                                        c.currentT,
                                        null,
                                        c.mUCryptogram
                                      )}
                                    >
                                      {isDepVisible && <i toggle-btn='toggle-btn' className='icon icon-donwload' />}
                                      <I18n attr={{'toggle-btn': 'toggle-btn'}} tKey='common.deposit' />
                                    </div>
                                    <div
                                      toggle-btn='toggle-btn'
                                      className={`btn ${isWithdrawVisible ? 'open' : ''}`}
                                      onClick={this.toggleForm.bind(this, w.identificatSymbol, null, null, c.currentT)}
                                    >
                                      {isWithdrawVisible && (
                                        <i toggle-btn='toggle-btn' className='icon icon-donwload-top' />
                                      )}
                                      <I18n
                                        attr={{'toggle-btn': 'toggle-btn'}}
                                        toggle-btn='toggle-btn'
                                        tKey='common.withdrawal'
                                      />
                                    </div>
                                  </div>
                                </td>
                              )}
                            {!c.activityFlag &&
                              (c.mUCryptogram === 'XRP' || c.mUCryptogram === 'EOS' || c.mUCryptogram === 'LTC') && (
                                <td className='td-actions text-center'>
                                  <i className='icon icon-maintenance' /> <I18n tKey='common.under_maintenance' />
                                </td>
                              )}
                          </tr>
                          <tr
                            id={`deposit-${w.identificatSymbol}`}
                            className='open-tr'
                            style={{display: isWithdrawVisible ? 'table-row' : 'none'}}
                          >
                            {this.state.withdrawNetworkChain === undefined && withdrawNetworkShouldBeSelected ? (
                              <td colSpan='7'>
                                <div className='withdraw-network-select'>
                                  <div>
                                    <I18n tKey='balances.token_chain_diclaimer' />
                                  </div>
                                  <div style={{marginBottom: 8}}>
                                    <I18n tKey='balances.select_blockchain' />:
                                  </div>
                                  <div className='deposit-chain-btns'>
                                    {!isFireBlocksOnly && (
                                      <button
                                        className='btn btn-purple btn-full'
                                        onClick={() =>
                                          this.setState({withdrawNetworkChain: {network: DEFAULT_NETWORK}})
                                        }
                                        toggle-btn='toggle-btn'
                                      >
                                        {getBlockchainsForCurrency(c.mUCryptogram)}
                                      </button>
                                    )}

                                    {(this.props.GetWithdrawChainsReducer.data.respItemCollection || [])
                                      .filter(
                                        ({internalCode}) =>
                                          !HIDDEN_FIREBLOCKS.some(cur => cur === internalCode) &&
                                          !WITHDRAWAL_FIREBLOCKS_EXCEPTION.some(cur => cur === internalCode)
                                      )
                                      .filter(
                                        ({internalCode}) =>
                                          internalCode.toLowerCase() === w.monetaryUnitCode.toLowerCase()
                                      )
                                      .map(item => (
                                        <button
                                          className='btn btn-purple btn-full icon-blockchain'
                                          onClick={() => this.setState({withdrawNetworkChain: item})}
                                          toggle-btn='toggle-btn'
                                          key={item.id}
                                        >
                                          {blockchainIcons[item.network]} {item.description}
                                        </button>
                                      ))}
                                  </div>
                                </div>
                              </td>
                            ) : (
                              <td colSpan='7'>
                                {!settings.googleAuthAllowanceFlag && (
                                  <div className='add-info '>
                                    <div
                                      className='add-info-header chain-selector'
                                      style={{justifyContent: 'flex-start'}}
                                    >
                                      <I18n tKey='balances.required_2fa' />
                                      &nbsp;
                                      <Link to={URLMAP.PROFILE}>
                                        <I18n tKey='popup.2f_confirm_enable_title' />
                                      </Link>
                                    </div>
                                  </div>
                                )}
                                {settings.googleAuthAllowanceFlag && (
                                  <div className='add-info'>
                                    <Loader open={GtiRequestReducer.fetching} />
                                    <SuccessDialog open={successDialogIsOpen} onClose={this.handleOpenSuccessDialog}>
                                      {GetGtiStatusReducer.data.status === 'Linked' ? (
                                        <I18n tKey='balances.fiat_deposit_confirmed' />
                                      ) : (
                                        <I18n tKey='balances.linking_successfully' />
                                      )}
                                    </SuccessDialog>
                                    <ErrorDialog open={errorDialogIsOpen} onClose={this.handleOpenErrorDialog}>
                                      {GtiRequestReducer.error &&
                                        showGtiAccountLinkingError(
                                          GtiRequestReducer.error.error,
                                          LngReducer.dictionary
                                        )}
                                    </ErrorDialog>
                                    {GetGtiStatusReducer.data.status !== 'LinkInitiated' && (
                                      <div className='add-info-header'>
                                        <div className='add-info-header-text'>
                                          <I18n tKey='balances.you_have' />{' '}
                                          <CurrencyView
                                            appearance={c.currentT}
                                            amount={w.aValue}
                                            type={c.mUCryptogram}
                                          />{' '}
                                          {c.mUCryptogram} <I18n tKey='balances.available_withdrawal' />,{' '}
                                          <CurrencyView
                                            appearance={c.currentT}
                                            amount={w.resValue}
                                            type={c.mUCryptogram}
                                          />{' '}
                                          {c.mUCryptogram} <I18n tKey='balances.is_held' />
                                          <Link to='/balances/TransactionHistory'>
                                            {' '}
                                            <I18n tKey='balances.on_orders' />
                                          </Link>
                                        </div>
                                        {(c.currentT !== 'FIAT' ||
                                          (c.currentT === 'FIAT' && GetGtiStatusReducer.data.status === 'Linked')) && (
                                          <Fragment>
                                            {c.mUCryptogram === 'EUR' && this.state.withdrawEurMethod === 'SEPA' ? (
                                              <Fragment />
                                            ) : (
                                              <div className='add-info-header-text'>
                                                <div>
                                                  <I18n tKey='balances.trx_fee' />:{' '}
                                                  <span>
                                                    {this.state.withdrawNetworkChain &&
                                                    this.state.withdrawNetworkChain.withdrawFee !== undefined
                                                      ? this.state.withdrawNetworkChain.withdrawFee
                                                      : withdrawFee}{' '}
                                                    {c.currentT === 'FIAT' ? '' : c.mUCryptogram}
                                                  </span>
                                                </div>
                                                {!!convertedTxMin && (
                                                  <div>
                                                    <I18n tKey='common.min_amount' />
                                                    :&nbsp;
                                                    <CurrencyView
                                                      appearance={c.currentT}
                                                      type={txMinCurrency}
                                                      amount={convertedTxMin}
                                                      useWithdrawPrecision={true}
                                                      showType={true}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </Fragment>
                                        )}
                                      </div>
                                    )}

                                    {(c.mUCryptogram === 'USD' || c.mUCryptogram === 'EUR') && (
                                      <div className='deposit-method-select-container'>
                                        <span className='deposit-method-select'>
                                          <I18n tKey='balances.withdrawal_method' />:
                                        </span>
                                        <input
                                          type='radio'
                                          id={`withdrawGupRadio${c.mUCryptogram}`}
                                          name={`withdrawRadio${c.mUCryptogram}`}
                                          onChange={this.changeWithdrawFiatMethod}
                                          value='gup'
                                          className='custom-radio-button'
                                          checked={
                                            (c.mUCryptogram === 'USD' && this.state.withdrawUsdMethod === 'gup') ||
                                            (c.mUCryptogram === 'EUR' && this.state.withdrawEurMethod === 'gup')
                                          }
                                        />
                                        <label htmlFor={`withdrawGupRadio${c.mUCryptogram}`} className='quantum_label'>
                                          <GlobalUnitPayIcon className='deposit-method-select-label' />
                                          <div
                                            className='gupay_label'
                                            style={{
                                              color: this.props.ThemeReducer.darkTheme ? 'rgb(205, 210, 214)' : 'black',
                                            }}
                                          >
                                            <div>GLobal</div>
                                            <div>Unit Pay</div>
                                          </div>
                                        </label>
                                        {c.mUCryptogram === 'EUR' && (
                                          <Fragment>
                                            <input
                                              type='radio'
                                              id={`withdrawSEPARadio${c.mUCryptogram}`}
                                              name={`withdrawRadio${c.mUCryptogram}`}
                                              value='SEPA'
                                              onChange={this.changeWithdrawFiatMethod}
                                              checked={
                                                c.mUCryptogram === 'EUR' && this.state.withdrawEurMethod === 'SEPA'
                                              }
                                              className='custom-radio-button'
                                            />
                                            <label
                                              style={{marginLeft: 30}}
                                              htmlFor={`withdrawSEPARadio${c.mUCryptogram}`}
                                              className='quantum_label'
                                            >
                                              <div style={{display: 'flex', alignItems: 'center'}}>
                                                <SEPAIcon width={50} height={30} />
                                                <div
                                                  style={{
                                                    color: this.props.ThemeReducer.darkTheme
                                                      ? 'rgb(205, 210, 214)'
                                                      : 'black',
                                                    fontSize: 16,
                                                  }}
                                                >
                                                  SEPA
                                                </div>
                                              </div>
                                            </label>
                                          </Fragment>
                                        )}
                                      </div>
                                    )}

                                    {((c.mUCryptogram === 'EUR' && this.state.withdrawEurMethod === 'gup') ||
                                      (c.mUCryptogram === 'USD' && this.state.withdrawUsdMethod === 'gup')) && (
                                      <Fragment>
                                        {(!GetGtiStatusReducer.data.status ||
                                          GetGtiStatusReducer.data.status === 'NotLinked') &&
                                          gtiNotLinkedLayout}
                                        {GetGtiStatusReducer.data.status === 'LinkInitiated' && gtiLinkInitiatedLayout}
                                        {GetGtiStatusReducer.data.status === 'ConfirmedByUser' &&
                                          gtiConfirmedByUserLayout}
                                        {GetGtiStatusReducer.data.status === 'RejectedByAdmin' &&
                                          gtiRejectedByAdminLayout}
                                      </Fragment>
                                    )}

                                    {(c.mUCryptogram !== 'USD' && c.mUCryptogram !== 'EUR') ||
                                    (c.mUCryptogram === 'EUR' && this.state.withdrawEurMethod === 'gup') ||
                                    (c.mUCryptogram === 'USD' && this.state.withdrawUsdMethod === 'gup') ? (
                                      <Fragment>
                                        <div
                                          className={`add-info-body balances-info-body ${
                                            (c.mUCryptogram === 'USD' || c.mUCryptogram === 'EUR') &&
                                            !settings.googleAuthAllowanceFlag
                                              ? 'add-info-withdraw'
                                              : (c.mUCryptogram === 'USD' || c.mUCryptogram === 'EUR') &&
                                                settings.googleAuthAllowanceFlag
                                              ? 'add-info-withdraw add-info-twoFa'
                                              : ''
                                          }`}
                                        >
                                          {((c.mUCryptogram !== 'USD' && c.mUCryptogram !== 'EUR') ||
                                            ((c.mUCryptogram === 'USD' || c.mUCryptogram === 'EUR') &&
                                              GetGtiStatusReducer.data.status === 'Linked')) && (
                                            <Fragment>
                                              <Loader open={WithdrawRequestReducer.fetching} />
                                              <EmailCheckingDialog
                                                open={emailCheckingDialogIsOpen}
                                                onClose={this.handleOpenEmailCheckingDialog}
                                              >
                                                <I18n tKey='balances.email_checking' />
                                              </EmailCheckingDialog>
                                              <ErrorDialog
                                                open={errorDialogIsOpen}
                                                onClose={this.handleOpenErrorDialog}
                                              >
                                                {WithdrawRequestReducer.error &&
                                                  showGtiWithdrawError(
                                                    WithdrawRequestReducer.error.error,
                                                    LngReducer.dictionary
                                                  )}
                                              </ErrorDialog>

                                              {c.mUCryptogram !== 'USD' && c.mUCryptogram !== 'EUR' && (
                                                <div className='add-info-body-item'>
                                                  <div className='box-control'>
                                                    <label className='label'>
                                                      <I18n tKey='common.address' />
                                                    </label>
                                                    <input
                                                      type='text'
                                                      name='addr'
                                                      value={this.state.withdraw.addr}
                                                      className={
                                                        'form-control ' +
                                                        (this.state.withdraw.addressHasError ? 'error' : '')
                                                      }
                                                      onChange={this.onWithdrawChange.bind(this)}
                                                    />
                                                  </div>

                                                  {MEMO_CAPABLE_CRYPTO.includes(
                                                    w.monetaryUnitCode.toLocaleLowerCase()
                                                  ) && (
                                                    <div className='box-control'>
                                                      <label className='label'>
                                                        <I18n tKey='common.your' /> {c.mUBanner}{' '}
                                                        <I18n tKey='common.memo' />{' '}
                                                      </label>
                                                      <input
                                                        className='form-control'
                                                        value={this.state.withdraw.memo}
                                                        name='memo'
                                                        type='text'
                                                        onChange={this.onWithdrawChange.bind(this)}
                                                      />
                                                    </div>
                                                  )}
                                                  {withdrawError && (
                                                    <div className='red'>
                                                      <I18n tKey={withdrawError} param={withdrawErrorParam} />
                                                    </div>
                                                  )}
                                                  {withData.currentSt === 'WAITING_FOR_EMAIL_CONFIRM' &&
                                                    withData.monetaryUnitCode === cur && (
                                                      <div>
                                                        <I18n tKey='balances.confirm_withdraw' />
                                                      </div>
                                                    )}
                                                </div>
                                              )}

                                              <div className='add-info-body-item'>
                                                <div className='box-control'>
                                                  <label className='label'>
                                                    <I18n tKey='balances.amount_to_receive' />
                                                  </label>
                                                  <CurrencyInput
                                                    onChange={this.changeWithdrawAmount.bind(this, c.currentT)}
                                                    name='amount'
                                                    value={this.state.withdraw.amount}
                                                    className={
                                                      'form-control ' +
                                                      (this.state.withdraw.amountHasError ? 'error' : '')
                                                    }
                                                    useWithdrawPrecision={true}
                                                    currencyType={c.mUCryptogram}
                                                  />
                                                  {this.state.withdraw && !!this.state.withdraw.amountWithFee && (
                                                    <div style={{textAlign: 'right', fontSize: '0.85em'}}>
                                                      <I18n tKey='balances.amount_with_fee' />:{' '}
                                                      <CurrencyView
                                                        appearance={c.currentT}
                                                        amount={this.state.withdraw.amountWithFee}
                                                        useWithdrawPrecision={true}
                                                        type={c.mUCryptogram}
                                                      />
                                                    </div>
                                                  )}
                                                  {(c.mUCryptogram === 'USD' || c.mUCryptogram === 'EUR') &&
                                                    withdrawError && (
                                                      <div className='red'>
                                                        <I18n tKey={withdrawError} param={withdrawErrorParam} />
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                              {settings.googleAuthAllowanceFlag && (
                                                <div className='add-info-body-item'>
                                                  <div className='box-control'>
                                                    <label className='label'>
                                                      <I18n tKey='common.2fa_code' />
                                                    </label>
                                                    <input
                                                      type='text'
                                                      name='twofaCode'
                                                      value={this.state.twofaCode}
                                                      className={
                                                        'form-control ' + (this.state.codeHasError ? 'error' : '')
                                                      }
                                                      onChange={this.onWithdrawChange.bind(this)}
                                                      maxLength={6}
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                              <div className='add-info-body-item btn-wrapper'>
                                                <a
                                                  className='btn btn-primary'
                                                  onClick={this.requestWithdraw.bind(
                                                    this,
                                                    curId,
                                                    curType,
                                                    c.mUCryptogram
                                                  )}
                                                >
                                                  <I18n tKey='common.withdrawal' />
                                                </a>
                                              </div>
                                            </Fragment>
                                          )}
                                        </div>

                                        {isMultichainCurrency(c.mUCryptogram) && (
                                          <Fragment>
                                            <div
                                              className='deposit-warning'
                                              style={{display: 'inline-block', margin: '0 0 1em 0'}}
                                            >
                                              <SvgWrapper className='ic-size-06' SvgCmp={WarningIc} />
                                              <I18n tKey='balances.withdraw_for_blockchain_warn' />:{' '}
                                              {this.getWithdrawNetworkName(c.mUCryptogram)}.
                                            </div>
                                          </Fragment>
                                        )}
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        <Loader open={WithdrawRequestReducer.fetching} />

                                        <EmailCheckingDialog
                                          open={emailCheckingDialogIsOpen}
                                          onClose={this.handleOpenEmailCheckingDialog}
                                        >
                                          <I18n tKey='balances.email_checking' />
                                        </EmailCheckingDialog>

                                        <ErrorDialog open={errorDialogIsOpen} onClose={this.handleOpenErrorDialog}>
                                          {/*{WithdrawRequestReducer.error &&*/}
                                          {/*  showGtiWithdrawError(*/}
                                          {/*    WithdrawRequestReducer.error.error,*/}
                                          {/*    LngReducer.dictionary*/}
                                          {/*  )}*/}
                                          {WithdrawRequestReducer.error && WithdrawRequestReducer.error.error}
                                        </ErrorDialog>

                                        <SepaWithdraw
                                          availableToWithdraw={w.aValue}
                                          currencyId={curId}
                                          isWithdrawFetching={this.state.isWithdrawFetching}
                                          requestSepaWithdraw={this.requestSepaWithdraw}
                                        />
                                      </Fragment>
                                    )}
                                  </div>
                                )}
                              </td>
                            )}
                          </tr>

                          <tr
                            id={`withdrawal-${w.identificatSymbol}`}
                            className='open-tr'
                            style={{display: isDepVisible ? 'table-row' : 'none'}}
                          >
                            <td colSpan='7'>
                              <div className='add-info add-info-deposit'>
                                {c.currentT === 'FIAT' ? (
                                  <div className='add-info-header'>
                                    <Loader open={GtiRequestReducer.fetching} />
                                    <SuccessDialog open={successDialogIsOpen} onClose={this.handleOpenSuccessDialog}>
                                      {GetGtiStatusReducer.data.status === 'Linked' ? (
                                        <I18n tKey='balances.fiat_deposit_confirmed' />
                                      ) : (
                                        <I18n tKey='balances.linking_successfully' />
                                      )}
                                    </SuccessDialog>
                                    <ErrorDialog open={errorDialogIsOpen} onClose={this.handleOpenErrorDialog}>
                                      {(GtiRequestReducer.error &&
                                        showGtiAccountLinkingError(
                                          GtiRequestReducer.error.error,
                                          LngReducer.dictionary
                                        )) ||
                                        (GtiDepositReduser.error &&
                                          showDepositGtiFiatError(
                                            GtiDepositReduser.error.error,
                                            LngReducer.dictionary
                                          ))}
                                    </ErrorDialog>
                                    <div className='deposit-method-select-container'>
                                      <span className='deposit-method-select'>
                                        <I18n tKey='balances.deposit_method_choice' />:
                                      </span>
                                      <div className='deposit-method-radio_group'>
                                        <div className='deposit-method-radio_group-quantum'>
                                          <input
                                            type='radio'
                                            id={`quantumRadio${c.mUCryptogram}`}
                                            name={`depositMethod${c.mUCryptogram}`}
                                            className='custom-radio-button'
                                            value='quantum'
                                            disabled={'disabled'}
                                            onChange={this.onDepositMethodChange}
                                            checked={
                                              (c.mUCryptogram === 'USD' &&
                                                this.state.chosenUsdDepositMethod === 'quantum') ||
                                              (c.mUCryptogram === 'EUR' &&
                                                this.state.chosenEurDepositMethod === 'quantum')
                                            }
                                          />
                                          <label htmlFor={`quantumRadio${c.mUCryptogram}`} className='quantum_label'>
                                            <QuantumClearanceIcon className='deposit-method-select-label' />
                                            <span
                                              style={{
                                                color: this.props.ThemeReducer.darkTheme
                                                  ? 'rgb(205, 210, 214)'
                                                  : 'black',
                                                fontSize: 16,
                                              }}
                                            >
                                              Quantum Clearance (temporary disabled)
                                            </span>
                                          </label>
                                        </div>
                                        <div className='deposit-method-radio_group-quantum'>
                                          <input
                                            type='radio'
                                            id={`gupRadio${c.mUCryptogram}`}
                                            name={`depositMethod${c.mUCryptogram}`}
                                            className='custom-radio-button'
                                            value='gup'
                                            onChange={this.onDepositMethodChange}
                                            checked={
                                              (c.mUCryptogram === 'USD' &&
                                                this.state.chosenUsdDepositMethod === 'gup') ||
                                              (c.mUCryptogram === 'EUR' && this.state.chosenEurDepositMethod === 'gup')
                                            }
                                          />
                                          <label htmlFor={`gupRadio${c.mUCryptogram}`} className='quantum_label'>
                                            <GlobalUnitPayIcon className='deposit-method-select-label' />
                                            <div
                                              className='gupay_label'
                                              style={{
                                                color: this.props.ThemeReducer.darkTheme
                                                  ? 'rgb(205, 210, 214)'
                                                  : 'black',
                                              }}
                                            >
                                              <div>GLobal</div>
                                              <div>Unit Pay</div>
                                            </div>
                                          </label>
                                        </div>
                                        {c.mUCryptogram === 'EUR' && (
                                          <div>
                                            <input
                                              type='radio'
                                              id={`sepaRadio${c.mUCryptogram}`}
                                              name={`depositMethod${c.mUCryptogram}`}
                                              className='custom-radio-button'
                                              value='SEPA'
                                              disabled={'disabled'}
                                              onChange={this.onDepositMethodChange}
                                              checked={this.state.chosenEurDepositMethod === 'SEPA'}
                                            />
                                            <label htmlFor={`sepaRadio${c.mUCryptogram}`} className='quantum_label'>
                                              <div
                                                style={{
                                                  color: this.props.ThemeReducer.darkTheme
                                                    ? 'rgb(205, 210, 214)'
                                                    : 'black',
                                                  fontSize: 16,
                                                }}
                                              >
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                  <SEPAIcon width={50} height={30} />
                                                  <div>SEPA (temporary disabled)</div>
                                                </div>
                                              </div>
                                            </label>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {c.mUCryptogram === 'EUR' && this.state.chosenEurDepositMethod === 'SEPA' && (
                                      <div className='sepa_form_container'>
                                        Temporary unavailable
                                        {/*<div className='sepa_row'>*/}
                                        {/*  <div className='sepa_title'>*/}
                                        {/*    <I18n tKey='deposit_sepa.account_name' />:*/}
                                        {/*  </div>*/}
                                        {/*  <div className='sepa_value'>*/}
                                        {/*    FNT*/}
                                        {/*    <CopyIc onClick={() => copy('FNT')} />*/}
                                        {/*  </div>*/}
                                        {/*</div>*/}
                                        {/*<div className='sepa_row'>*/}
                                        {/*  <div className='sepa_title'>*/}
                                        {/*    <I18n tKey='deposit_sepa.account_address' />:*/}
                                        {/*  </div>*/}
                                        {/*  <div className='sepa_value'>*/}
                                        {/*    LT153550020000021755 <CopyIc onClick={() => copy('LT153550020000021755')} />*/}
                                        {/*  </div>*/}
                                        {/*</div>*/}
                                        {/*<div className='sepa_row'>*/}
                                        {/*  <div className='sepa_title'>*/}
                                        {/*    <I18n tKey='deposit_sepa.user_uuid' />:*/}
                                        {/*  </div>*/}
                                        {/*  <div className='sepa_value'>*/}
                                        {/*    {SessionReducer.uuid} <CopyIc onClick={() => copy(SessionReducer.uuid)} />*/}
                                        {/*  </div>*/}
                                        {/*</div>*/}
                                        {/*<div className='sepa_attention'>*/}
                                        {/*  <WarningIc />*/}
                                        {/*  <I18n tKey='deposit_sepa.uuid_attention' />*/}
                                        {/*</div>*/}
                                        {/*<div className='sepa_deposit_fees'>*/}
                                        {/*  <SvgWrapper className='ic-size-06' SvgCmp={WarningGtiIc} />*/}
                                        {/*  <I18n tKey='deposit_sepa.fees' />*/}
                                        {/*</div>*/}
                                      </div>
                                    )}
                                    {((c.mUCryptogram === 'USD' && this.state.chosenUsdDepositMethod === 'quantum') ||
                                      (c.mUCryptogram === 'EUR' &&
                                        this.state.chosenEurDepositMethod === 'quantum')) && (
                                      <div className='adv_form_container'>
                                        <AdvCashForm
                                          c={c}
                                          w={w}
                                          FeesSettingsReducer={FeesSettingsReducer}
                                          isFeelessTransactions={plan.feelessTransactions}
                                        />
                                      </div>
                                    )}
                                    {((c.mUCryptogram === 'USD' && this.state.chosenUsdDepositMethod === 'gup') ||
                                      (c.mUCryptogram === 'EUR' && this.state.chosenEurDepositMethod === 'gup')) && (
                                      <div className='adv_form_container'>
                                        {(c.mUCryptogram === 'USD' || c.mUCryptogram === 'EUR') &&
                                          (!GetGtiStatusReducer.data.status ||
                                            GetGtiStatusReducer.data.status === 'NotLinked') &&
                                          gtiNotLinkedLayout}
                                        {(c.mUCryptogram === 'USD' || c.mUCryptogram === 'EUR') &&
                                          GetGtiStatusReducer.data.status === 'LinkInitiated' &&
                                          gtiLinkInitiatedLayout}
                                        {(c.mUCryptogram === 'USD' || c.mUCryptogram === 'EUR') &&
                                          GetGtiStatusReducer.data.status === 'ConfirmedByUser' &&
                                          gtiConfirmedByUserLayout}
                                        {(c.mUCryptogram === 'USD' || c.mUCryptogram === 'EUR') &&
                                          GetGtiStatusReducer.data.status === 'RejectedByAdmin' &&
                                          gtiRejectedByAdminLayout}
                                        {(c.mUCryptogram === 'USD' || c.mUCryptogram === 'EUR') &&
                                          GetGtiStatusReducer.data.status === 'Linked' && (
                                            <Fragment>
                                              <div className='box-control gti-section'>
                                                <div className='gti-input-section input-fiat'>
                                                  <label className='label'>
                                                    <I18n tKey='balances.amount_to_receive' />
                                                  </label>
                                                  <input
                                                    type='text'
                                                    name='gtiId'
                                                    value={
                                                      c.mUCryptogram === 'USD'
                                                        ? this.state.gtiDepositUsdAmount
                                                        : this.state.gtiDepositEurAmount
                                                    }
                                                    className='form-control withdrawal_gti_fiat'
                                                    onChange={this.changeGtiDepositAmount.bind(this, c.mUCryptogram)}
                                                  />
                                                </div>
                                                <button
                                                  className='btn btn-primary btn-md btn-full btn-gti-deposit'
                                                  onClick={() => {
                                                    this.props.requestGtiDeposit({
                                                      amount:
                                                        c.mUCryptogram === 'USD'
                                                          ? this.state.gtiDepositUsdAmount
                                                          : this.state.gtiDepositEurAmount,
                                                      currencyCode: c.mUCryptogram,
                                                      walletId: w.identificatSymbol,
                                                    });
                                                  }}
                                                  disabled={
                                                    GtiDepositReduser.fetching ||
                                                    (c.mUCryptogram === 'USD' && !this.state.gtiDepositUsdAmount) ||
                                                    (c.mUCryptogram === 'EUR' && !this.state.gtiDepositEurAmount)
                                                  }
                                                >
                                                  <I18n tKey='common.add_deposit' />
                                                </button>
                                              </div>
                                              {!plan.feelessTransactions && (
                                                <p style={{paddingTop: '15px'}}>
                                                  * <I18n tKey='balances.additional_fee' />:{' '}
                                                  {actionFees && actionFees.depositFee}
                                                </p>
                                              )}
                                            </Fragment>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <Fragment>
                                    {this.state.needChainSelect &&
                                      !this.state.depositSelectedChain &&
                                      this.state.additionalDepositSelectedChain === null && (
                                        <Fragment>{this.renderDepositChainSelector()}</Fragment>
                                      )}

                                    {this.state.additionalDepositSelectedChain !== null && (
                                      <MultiChainDepositData
                                        currencyCode={w.monetaryUnitCode}
                                        currencyName={c.mUBanner}
                                        walletId={this.state.selectedForDeposit}
                                        chainId={this.state.additionalDepositSelectedChain}
                                      />
                                    )}

                                    {(!this.state.needChainSelect || !!this.state.depositSelectedChain) && (
                                      <Fragment>
                                        <div className='add-info-header'>
                                          {(GetDepositAddressReducer.fetching ||
                                            WsWalletReducer.isDepositAddressLoading) && <I18n tKey='common.loading' />}
                                          <div className='add-info-header-item'>
                                            {depAddr && (
                                              <Fragment>
                                                <div className='box-control'>
                                                  <label className='label'>
                                                    <I18n tKey='common.your' /> {c.mUBanner}{' '}
                                                    <I18n tKey='balances.deposit_address' />{' '}
                                                  </label>
                                                  <input
                                                    value={depAddr || ''}
                                                    className='form-control'
                                                    readOnly={true}
                                                    type='text'
                                                  />
                                                </div>
                                                {depMemo && (
                                                  <div className='box-control'>
                                                    <label className='label'>
                                                      <I18n tKey='common.your' /> {c.mUBanner}{' '}
                                                      <I18n tKey='common.memo' />{' '}
                                                    </label>
                                                    <input
                                                      value={depMemo}
                                                      className='form-control'
                                                      readOnly={true}
                                                      type='text'
                                                    />
                                                  </div>
                                                )}
                                              </Fragment>
                                            )}
                                            {depError && !depAddr && <div className='red'>{depError}</div>}
                                          </div>

                                          {depAddr && (
                                            <Fragment>
                                              <div className='add-info-header-item'>
                                                <a
                                                  className='action-ic-block'
                                                  onClick={this.copyToClipboard.bind(this, depAddr, 'copiedAddress')}
                                                >
                                                  <SvgWrapper
                                                    themeWatch
                                                    className='icon icon-copy ic-size-08'
                                                    SvgCmp={CopyIc}
                                                  />
                                                  <I18n tKey='common.copy' />
                                                  {this.state.copiedAddress ? (
                                                    <div className='copied-wrapper'>
                                                      <I18n tKey='common:copied' />
                                                    </div>
                                                  ) : null}
                                                </a>
                                                {depMemo && (
                                                  <a
                                                    className='action-ic-block'
                                                    onClick={this.copyToClipboard.bind(this, depMemo, 'copiedMemo')}
                                                  >
                                                    <SvgWrapper
                                                      themeWatch
                                                      className='icon icon-copy ic-size-08'
                                                      SvgCmp={CopyIc}
                                                    />
                                                    <I18n tKey='common.copy' />
                                                    {this.state.copiedMemo ? (
                                                      <div className='copied-wrapper'>
                                                        <I18n tKey='common:copied' />
                                                      </div>
                                                    ) : null}
                                                  </a>
                                                )}
                                              </div>
                                            </Fragment>
                                          )}
                                          <div className='add-info-header-item'>
                                            {depAddr && (
                                              <Fragment>
                                                <a
                                                  className='action-ic-block'
                                                  onClick={this.props.showQRDeposit.bind(
                                                    this,
                                                    c.mUBanner,
                                                    depAddr,
                                                    false
                                                  )}
                                                >
                                                  <SvgWrapper
                                                    themeWatch
                                                    className='icon ic-size-06 icon-qr ic-color-00'
                                                    SvgCmp={QrIc}
                                                  />
                                                  <I18n tKey='common.show_qr' />
                                                </a>
                                                {depMemo && (
                                                  <a
                                                    className='action-ic-block'
                                                    onClick={this.props.showQRDeposit.bind(
                                                      this,
                                                      c.mUBanner,
                                                      depMemo,
                                                      true
                                                    )}
                                                  >
                                                    <SvgWrapper
                                                      themeWatch
                                                      className='icon ic-size-06 icon-qr ic-color-00'
                                                      SvgCmp={QrIc}
                                                    />
                                                    <I18n tKey='common.show_qr' />
                                                  </a>
                                                )}
                                              </Fragment>
                                            )}
                                          </div>
                                          <div className='add-info-header-item'>
                                            {depAddr && (
                                              <a
                                                className='action-ic-block'
                                                onClick={this.requestDepAddr.bind(this, w.identificatSymbol)}
                                              >
                                                <SvgWrapper
                                                  themeWatch
                                                  className='icon icon-refresh ic-size-02'
                                                  SvgCmp={RefreshIc}
                                                />
                                                <I18n tKey='common.refresh' />
                                              </a>
                                            )}
                                            {depAddr && depError && (
                                              <div className='red' style={{textAlign: 'right', width: '15em'}}>
                                                {depError}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className='add-info-body'>
                                          <div className='add-info-body-text'>
                                            <I18n tKey='balances.after_making' />
                                            &nbsp;
                                            <Link to='/balances/TransactionHistory'>
                                              <I18n tKey='balances.pages' />{' '}
                                            </Link>
                                            {minDepositAmount > 0 && (
                                              <div>
                                                <strong>
                                                  <I18n tKey='balances.min_deposit_amount' />: {minDepositAmount}
                                                </strong>
                                              </div>
                                            )}
                                            {w.monetaryUnitCode.toLocaleLowerCase() == 'btc' && (
                                              <div>
                                                <strong>
                                                  <I18n tKey='balances.transaction_delay_warn' />
                                                </strong>
                                              </div>
                                            )}
                                            {!!depMemo && (
                                              <div className='deposit-warning'>
                                                <SvgWrapper className='ic-size-06' SvgCmp={WarningIc} />
                                                <I18n tKey='balances.transaction_memo_warn' />
                                              </div>
                                            )}
                                            {!!minDepositAmount && (
                                              <div className='deposit-warning'>
                                                <SvgWrapper className='ic-size-06' SvgCmp={WarningIc} />
                                                <I18n tKey='balances.transaction_amount_warn' />
                                              </div>
                                            )}
                                            {!!this.state.depositSelectedChain && (
                                              <div className='deposit-warning'>
                                                <SvgWrapper className='ic-size-06' SvgCmp={WarningIc} />
                                                <I18n tKey='balances.address_for_blockchain_warn' />:{' '}
                                                {this.state.depositSelectedChain}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </Fragment>
                                    )}
                                  </Fragment>
                                )}
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      ) : (
                        <tr key={i} />
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className='block-balance-footer'>
              {(this.state.hideZeroBalances || this.state.filter) && (
                <div className='block-balance-footer-item'>
                  <Fragment>
                    <I18n tKey='balances.table_filter' />{' '}
                    <a onClick={this.clearFilter}>
                      <I18n tKey='common.clear_filters' />
                    </a>
                  </Fragment>
                </div>
              )}
              <div className='block-balance-footer-item'>
                <I18n tKey='balances.financial_support_text' />{' '}
                <Link to={'/page/faq/about'}>
                  <I18n tKey='common.faq_page' />
                </Link>
              </div>
              <div className='block-balance-footer-item'>
                <I18n tKey='balances.looking' />{' '}
                <Link to={URLMAP.BALANCES + '/TransactionHistory'}>
                  <I18n tKey='balances.pages' />?
                </Link>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  WsTickersReducer,
  GetDepositAddressReducer,
  MarketListReducer,
  WithdrawRequestReducer,
  UserSettingsReducer,
  WithdrawUsageReducer,
  AccountListReducer,
  SessionReducer,
  WsWalletReducer,
  CurrencyListReducer,
  DepositAddressesListReducer,
  WsWalletListReducer,
  GtiRequestReducer,
  GetGtiStatusReducer,
  LngReducer,
  GtiDepositReduser,
  ThemeReducer,
  FeesSettingsReducer,
  GetWithdrawChainsReducer,
}) => ({
  WithdrawUsageReducer,
  GetDepositAddressReducer,
  AccountListReducer,
  WsWalletListReducer,
  DepositAddressesListReducer,
  SessionReducer,
  WsWalletReducer,
  CurrencyListReducer,
  UserSettingsReducer,
  WithdrawRequestReducer,
  WsTickersReducer,
  MarketListReducer,
  GtiRequestReducer,
  GetGtiStatusReducer,
  LngReducer,
  GtiDepositReduser,
  ThemeReducer,
  FeesSettingsReducer,
  GetWithdrawChainsReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    clearWithdraw: () => dispatch({type: 'WITHDRAW_REQUEST_ACTION_CLEAR'}),
    clearAdditionalDepositChainData: () => dispatch({type: Actions.getDepositChainInfo.events.onClear}),
    showQRDeposit: (currency, address, isMemo) =>
      dispatch({
        type: OPEN_POPUP,
        name: DEPOSIT_QR_CODE_POPUP,
        data: {currency, address, isMemo},
      }),
    ...bindActionCreators(
      {
        getWithdrawChains: Actions.getWithdrawChains.action,
        getDepositAddresses: Actions.DepositAddressesList.action,
        listDepositAddresses: Actions.AccountDepositAddressesList.action,
        requestDepositAddress: Actions.GetDepositAddress.action,
        getWithdrawUsage: Actions.WithdrawUsage.action,
        getUserSettings: Actions.UserSettings.action,
        requestWithdraw: Actions.WithdrawRequest.action,
        getAccountList: Actions.AccountList.action,
        requestGti: Actions.GtiRequest.action,
        getCurrentGtiStatus: Actions.GetGtiStatus.action,
        requestGtiDeposit: Actions.GtiDepositRequest.action,
        getFees: Actions.FeesSettings.action,
        getDepositChainInfo: getDepositChainInfo.action,
      },
      dispatch
    ),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Balances));

export default connectedWithRouter;
